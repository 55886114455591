/* _base.scss is used to apply style to the base elements of the project no classes ids should be used in this file */

*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}