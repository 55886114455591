.ds-row {
    display: block;
    width: 100%;
    margin-left: -15px;
    margin-right: -15px;
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

[class^=".ds-col"] {
    float: left;
}

.ds-col-xs-1 {
     width: 10%;
}
.ds-col-xs-2 {
     width: 20%;
}
.ds-col-xs-3 {
    width: 30%;
}
.ds-col-xs-4{
    width: 40%;
}
.ds-col-xs-5 {
     width: 50%;
}
.ds-col-xs-6 {
    width: 60%;
}
.ds-col-xs-7 {
    width: 70%;
}
.ds-col-xs-8 {
    width: 80%;
}
.ds-col-xs-9 {
    width: 90%;
}
.ds-col-xs-10 {
    width: 100%;
}

/* querys ******************************************************************
****************************************************************************/

@media (min-width: 525px){
    .ds-col-sm-1 {
        width: 10%;
    }
    .ds-col-sm-2 {
        width: 20%;
    }
    .ds-col-sm-3 {
        width: 30%;
    }
    .ds-col-sm-4{
        width: 40%;
    }
    .ds-col-sm-5 {
        width: 50%;
    }
    .ds-col-sm-6 {
        width: 60%;
    }
    .ds-col-sm-7 {
        width: 70%;
    }
    .ds-col-sm-8 {
        width: 80%;
    }
    .ds-col-sm-9 {
        width: 90%;
    }
    .ds-col-sm-10 {
        width: 100%;
    }
}


/* querys ******************************************************************
****************************************************************************/

@media (min-width: 768px){
    .ds-col-md-1 {
        width: 10%;
    }
    .ds-col-md-2 {
        width: 20%;
    }
    .ds-col-md-3 {
        width: 30%;
    }
    .ds-col-md-4{
        width: 40%;
    }
    .ds-col-md-5 {
        width: 50%;
    }
    .ds-col-md-6 {
        width: 60%;
    }
    .ds-col-md-7 {
        width: 70%;
    }
    .ds-col-md-8 {
        width: 80%;
    }
    .ds-col-md-9 {
        width: 90%;
    }
    .ds-col-md-10 {
        width: 100%;
    }
}


/* querys ******************************************************************
****************************************************************************/


@media (min-width: 992px){
    .ds-col-lg-1 {
        width: 10%;
    }
    .ds-col-lg-2 {
        width: 20%;
    }
    .ds-col-lg-3 {
        width: 30%;
    }
    .ds-col-lg-4{
        width: 40%;
    }
    .ds-col-lg-5 {
        width: 50%;
    }
    .ds-col-lg-6 {
        width: 60%;
    }
    .ds-col-lg-7 {
        width: 70%;
    }
    .ds-col-lg-8 {
        width: 80%;
    }
    .ds-col-lg-9 {
        width: 90%;
    }
    .ds-col-lg-10 {
        width: 100%;
    }
}


/* querys ******************************************************************
****************************************************************************/


@media (min-width: 1200px){
    .ds-col-xl-1 {
        width: 10%;
    }
    .ds-col-xl-2 {
        width: 20%;
    }
    .ds-col-xl-3 {
        width: 30%;
    }
    .ds-col-xl-4{
        width: 40%;
    }
    .ds-col-xl-5 {
        width: 50%;
    }
    .ds-col-xl-6 {
        width: 60%;
    }
    .ds-col-xl-7 {
        width: 70%;
    }
    .ds-col-xl-8 {
        width: 80%;
    }
    .ds-col-xl-9 {
        width: 90%;
    }
    .ds-col-xl-10 {
        width: 100%;
    }
}


