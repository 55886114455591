$gray: #ddd;

.ex-set-1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px 0 0;
  margin: 0 auto;
  list-style: none;
  text-align: center;
  position: relative;
  .ex-item {
    display: inline-block;
    min-height: 20px;
    width: 25%;
    vertical-align: top;
    padding: 20px 10px 0px;
    transition: 0.35s ease-in-out ;
    @media (max-width: 1100px){ //md
     width: 33%;
    }
    @media (max-width: 650px){ //sm
      width: 50%;
    }
    @media (max-width: 480px){ //xs
      padding: 5px 5px;
    }
  }
}
//fallback for browsers without flexbox support
.no-flexbox {
  .ex-set-1 {
    display: block;
    &__card {
      width: 18%;
      @media (max-width: 1200px){ //lg
        width: 19%;
      }
      @media (max-width: 992px){ //md
        width: 23%;
      }
      @media (max-width: 768px){ //sm
        width: 31%;
      }
      @media (max-width: 480px){ //xs
        width: 44%;
        padding: 5px 5px;
      }
    }
  }
}

.ex-item-content {
  border: 3px solid $grey-lite;
  display: block;
  width: 100%;
  padding: 0;
  background: rgba(0,0,0,0.6);
  cursor: pointer;
  position: relative;
  transition: border 0.2s linear, background 0.2s linear;
  &:hover {
    background: rgba(0,0,0,0.7);
  }
  &:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    margin: 0 auto;
    border-top: 10px solid $blue;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 56px;
    padding: 10px;
    @media (max-width: 480px){ //xs
      min-height: 46px;
      padding: 8px ;
    }
    span {
      display: inline-block;
      color: white;
      font-size: 14px;
      @media (max-width: 480px){ //xs
        font-size: 12px;
      }
    }
  }
}
.ex-active {
  .ex-item-content {
    border: 3px solid $blue;
    &:after {
      opacity: 1;
    }
  }
}

.ex-drawer {
  display: block;
  height: 0;
  border-radius: 3px;
  background: rgba(0,0,0,0.5);
  margin: 0 15px;
  text-align: left; 
  overflow: hidden;
  .ex-content {
    width: 100%;
    padding: 40px;
    color: #fff;
    @media (max-width: 480px ){ //xs
      padding: 15px;
    }
    p {
      margin-top: 10px;
      color: $white; 
      @media (max-width: 480px){ //xs
        font-size: 12px;
      }
    }
    h3 {
      color: $white;
    }
    ul {
      display: inline-block;
      padding: 0 0 0 18px;
      list-style: disc;
    }
    li {
      font-size: 1rem;
      color: $white;
      font-weight: 300;
      text-align: left;
      @media (max-width: 480px){ //xs
        font-size: 12px;
      }
    }
    a {
      color: $earth-yellow;
      &:hover {
        color: lighten($earth-yellow, 8%);
      }
    }
  }
  .close-drawer {
    display: block;
    margin-top: 30px;
    cursor: pointer;
    color: $white;
    text-align: right;
    @media (max-width: 480px){ //xs
      font-size: 14px;
    }
  }
}
