.nav-toggle {
	display: none;
}
.nav-close-toggle {
	display: none;
}

@media (max-width: $header-breakpoint){
	.nav-toggle {
		display: inline-block;
		width: 40px;
		height: 30px;
		position: absolute;
		top: 10px;
		right: 15px;
		background: transparent;
		span {
			border-radius: 2px;
			display: block;
			width: 100%;
			height: 2px;
			background: $toggle-color;
			position: absolute;
			top: calc(50% - 1px);
			left: 0;
			&:before,
			&:after {
				content: '';
				border-radius: 2px;
				display: block; 
				width: 100%;
				height: 2px;
				background: $toggle-color;
				position: absolute;
				left: 0;
			}
			&:after {
				top: 9px; 
			}
			&:before { 
				top: -9px; 
			}
		}
	}

	.nav-close-toggle {
		display: inline-block;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 15px;
		right: 15px;
		background: transparent;
		&:before,
		&:after {
			content: '';
			border-radius: 2px;
			display: block; 
			width: 100%;
			height: 2px;
			background: $toggle-color;
			position: absolute;
			left: 0;
		}
		&:after {
			top: calc(50% - 1px); 
			transform: rotate(45deg);
		}
		&:before { 
			top: calc(50% - 1px); 
			transform: rotate(135deg);
		}
	}
}


