.section-card {
    @extend .invert;
    background: $slate;
    border-radius: 10px;
    padding: 30px 40px 40px;
    text-align: center;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    @media (max-width: 500px){
        padding: 20px 20px 30px;
    }
    h1 {
        margin-top: 15px;
    }
    a {
        color: $earth-yellow;
        &:hover {
            color: lighten($earth-yellow, 8%);
        }
    }
}

