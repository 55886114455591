/* base header styles **************************************************************
************************************************************************************/
//mobile breakpoint
$header-breakpoint: 768px;
//colors
$header-bg: rgba(0,0,0,0.6);
$mobile-nav-bg: rgba(0,0,0,0.9);
$sub-nav-bg: rgba(0,0,0,0.5);
$sub2-nav-bg: lighten($sub-nav-bg, 2%);
$menu-text-color: #fff;
$active-menu-text: #000;
$toggle-color: #fff;
//transitions
$header-hover: 0.3s linear;

.header {
	display: block;
	width: 100%;
	background: $header-bg;
	height: 50px;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10000;
	ul {
		list-style: none;
	}
	a:focus {
		outline: initial;
	}
	a:active {
		color: inherit;
	}
}
.header__container {
	display: block;
	width: 100%;
	padding: 0 15px;
	position: relative;
	height: 100%;
}

.header-nav-container {
	display: block;
	width: 75%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
}

.header-logo {
	display: inline-block;
	color: $white;
	text-decoration: none;
	h2 {
		display: inline-block;
		font-size: 32px;
		font-family: 'Niconne', cursive;
		margin: 0;
		padding: 6px 0 0;
		color: $earth-yellow;
	}
}

.mobile-nav-head {
	display: none;
}
.nav-overlay{
	display: none;
}

@media (max-width: $header-breakpoint){
	.header {
	height: 50px;
	}
	.header__container {
	}
	.header-nav-container {
		background: $mobile-nav-bg;
		display: block;
		width: 75%;
		position: fixed;
		top: 0;
		right: -75%;
		bottom: 0;
		z-index: 10;
		transition: 0.4s ease;
	}
	.header-logo {
		text-decoration: none;
		h2 {
			padding: 3px 0 0;
		}
	}
	.mobile-nav-head {
		display: block;
		min-height: 60px;
		width: 100%;
		position: relative;
		padding: 8px 15px;
		h2 {
			display: inline-block;
			font-size: 32px;
			font-family: 'Niconne', cursive;
			margin: 0;
			padding: 0px 0px;
			color: $earth-yellow;
		}
	}
	.nav-overlay {
		display: block;
		width: 100%;
		background: transparent;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: 0.3s linear;
	}
	.nav-active {
		overflow: hidden;
		.header {
			.header-nav-container {
				right: 0;
			}
			.nav-overlay {
				background: rgba(208, 218, 217, 0.7);
			}
		}
	}
}
