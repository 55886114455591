.hero {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #000;
    opacity: 1;
    z-index: 1;
    animation: reveal 5s ease-in-out 1.5s forwards;
  }
  &__content {
    width: 50%;
    min-width: 255px;
    text-align: right;
    padding: 15px;
    position: absolute;
    top: 35%;
    left: 0;
    z-index: 1;
    h1 {
      color: #fff;
      opacity: 0;
      transform: translateY(30px);
      animation: textfade1 4s ease-in-out 0.5s forwards;
      font-size: 42px;
      font-weight: 300;
      @media (max-width: 769px){
        font-size: 32px;
      }
      span {
        font-size: 20px;
        font-weight: 200;
        display: block;
        color: #fff;
        opacity: 0;
        animation: textfade2 4s ease-in-out 0.5s forwards;
        &:before {
          content: '';
          height: 1px;
          width: 100%;
          background: #fff;
        }
      }
    }
  }
  .section-cue {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 20;
    @media (max-width: 768px) {
      bottom: 15px;
      right: 10px;
    }
  }
}

#home-hero {
  background-image: url(../img/home/home-hero-sm.jpg);
  @media (min-width: 451px){
    background-image: url(../img/home/home-hero-md.jpg);
  }
  @media (min-width: 769px){
    background-image: url(../img/home/home-hero-lg.jpg);
  }
  @media (min-width: 1440px){
    background-image: url(../img/home/home-hero-xl.jpg);
  }
}

@keyframes textfade1 {
 0% {opacity: 0;}
 38% {transform: translateY(35px);}
 40% {opacity: 1;}
 100% {opacity: 1;transform: translateY(0px);}
}
@keyframes textfade2 {
  0% {opacity: 0;}
  20% {opacity: 0;}
  60% {opacity: 1;}
  100% {opacity: 1;}
}
@keyframes reveal {
 0% {opacity: 1;}
 95% {opacity: 0;}
 100% {opacity: 0;}
}
