
$white: #ffffff;
$grey-lite: #f2f2f2;
$grey: #ccc;
$blue: #31c7ed;

$slate: #9aa8b1;

$earth-yellow: #e2d691;
$earth-blue: #d0dad9;
$earth-brown: #ceccc0;

$title-text: #555555;
$main-text: #777777;

$header-bg: rgba(0,0,0,.5);
$footer-bg: $header-bg;