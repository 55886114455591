.header-main-nav-container {
	display: inline-block;
	width: auto;
	height: 100%;
	margin: 0;
	padding: 0 15px 0 0;
	list-style: none;
	position: absolute;  
	right: 0;
	bottom: 0;
	text-align: right;
}

.header-main-nav {
	display: inline-block;
	width: auto;
	height: 100%;
	margin: 0;
	padding: 0;
	text-align: left;
	&:after {
		display: block;
		content: '';
		clear: both;
	}
	&__item {
		display: inline-block;
		width: auto;
		height: 100%;
		float: left;
		& > a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 15px 10px 10px;
			color: $menu-text-color;
			cursor: pointer;
			transition: $header-hover;
			&:hover {
				color: $earth-yellow;
			}
		}
		&.active {
			& > a { 
				background: $sub-nav-bg;
				color: $active-menu-text;
			}
		}
	}
	.nav-arrow {
		background: transparent;
		display: inline-block;
		margin: 0 0 3px 3px;
		width: 6px;
		height: 6px;
		transition: 0.3s linear;
		position: relative;
		&:after {
			content: '';
			background: transparent;
			border-bottom: 1px solid $white;
			border-right: 1px solid $white;
			display: inline-block;
			width: 100%;
			height: 100%;
			transform: rotate(45deg);
			transform-origin: 50% 50%;
			transition: 0.3s linear;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	.sub-active {
		& > a {
			.nav-arrow {
				transform-origin: 50% 50%;
				transform: rotate(-180deg);
			}
		}
	}	
}

@media (max-width: $header-breakpoint){
	.header-main-nav-container {
		display: block;
		width: 100%;
		height: auto;
		list-style: none;
		position: static;  
		right: initial;
		bottom: initial;
		text-align: left;
	}

	.header-main-nav {
		display: block;
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		text-align: left;
		&:after {
			display: block;
			content: '';
			clear: both;
		}
		&__item {
			display: block;
			width: 100%;
			height: auto;
			float: left;
			& > a {
				display: block;
				width: 100%;
				height: 100%;
				padding: 10px 15px;
				&:hover {
					color: $menu-text-color;
				}
			}
			&.active {
				& > a { 
					background: transparent;
				}
			}
		}	
	}
}