.hobby-ticker {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  color: $white;
  background: rgba(0,0,0,0.4);
  padding: 10px 15px;
  h3 {
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
    padding-right: 10px;
    margin: 0;
  }
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    min-width: 50%;
    list-style: none;
    position: relative;
    min-height: 20px;
    vertical-align: bottom;
    overflow: hidden;
  }
  li {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    transform: scale(0.5);
    left: -20px;
    &.active {
      animation: hobbySlide 3s ease-in-out forwards;
    }
  }
}

@keyframes hobbySlide {
 0% {opacity: 0; transform: scale(0.5); left: -20px;}
 30%{transform: scale(1); left: 0;}
 40% {opacity: 1;  left: 0;}
 70% {transform: scale(1);}
 100% {opacity: 0; transform: scale(0.5); left: 80px;}
}
