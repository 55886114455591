input,
textarea {
    outline: initial;
    box-shadow: 0 0 0 transparent;
    border: 1px solid $grey;
    border-radius: 2px;
    width: 100%;
    padding: 0.375rem 1rem;
    background: $white;
    margin: 0 0 5px;
    font-size: 1rem;
    color: $main-text;
    font-weight: 300;
    transition: border 0.3s linear;
    &:focus {
        border: 1px solid $blue;
    }
}

input {
    height: 2.25rem;
}

textarea {
    max-width: 100%;
}

label {
    display: block;
    color: $main-text;
    font-weight: 300;
}