/* _typography.scss is used for setting the base typography for the project */

html {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  @media (max-width: 768px){
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 20px 0;
  line-height: 1.4;
}

h1 {
  font-size: 2.125rem;
  font-weight: 300;
  color: $title-text;
  margin-bottom: 20px;
  font-family: 'Niconne', cursive;
}
h2,h3, {
  font-weight: 300;
}
p {
  font-size: 1rem;
  color: $main-text;
  font-weight: 300;
}

li{
  font-size: 1rem;
  color: $main-text;
  font-weight: 300;
}

a {
  color: $earth-yellow;
  transition: 0.3s ease;
  cursor: pointer;
    &:hover {
        color: lighten($earth-yellow, 8%);
    }
}

.invert {
  h1,h2,h3,h4,h5,h6,p,li {
    color: #fff;
  }
}