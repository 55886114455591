.split-bg-right {
  display: block;
  width: 100%;
  position: relative;
  min-height: 400px;
  .split-bg__img {
    display: inline-block;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .split-container {
    display: inline-block;
    width: 50%;
    padding: 0px;
    float: left;
  }
  .split-content {
    display: block;
    width: 100%;
    padding: 0px 40px 0 15px;
    margin: $section-space 0;
  }
}

@media (max-width: 700px){
  .split-bg-right {
    display: block;
    width: 100%;
    position: relative;
    min-height: 400px;
    .section-container {
    }
    .split-bg__img {
      display: block;
      width: 100%;
      height: 300px;
      position: relative;
      top: initial;
      right: initial;
      bottom: initial;
    }
    .split-container {
      display: block;
      width: 100%;
      float: initial;
    }
    .split-content {
      padding: 0 15px;
    }
  }
}

#bio-img {
  background-image: url(../img/home/bio.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
