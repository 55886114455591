.ex-cards {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
 
}
 .ex-item {
    display: inline-block;
    width: 32.25%;
    padding: 0 15px;
    margin: 10px 0 20px;
    vertical-align: top;
  }
  .ex-item-content {
    width: 100%;
    background: #999;
    min-height: 10px;
  }
  .ex-drawer-content {
    display: none;
  }
  .ex-drawer {
    display: none;
    width: 100%;
    background: #eee;
  }
  .ex-content {
    opacity: 0;
    transition: 0.6s ease-in;
  }
  .ex-active {
      .ex-content {
        opacity: 1;
      }
    }
