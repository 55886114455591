.footer {
    display: block;
    background: $footer-bg;
    width: 100%;
    height: 70px;
    position: relative;
    @media (max-width: 768px) {
        height: auto;    
    }
    .social {
        display: block;
        width: 100%;
        text-align: center;
        padding: 10px 0 5px;
        a {
            border: 2px solid $white;
            border-radius: 50%;
            display: inline-block;
            background: $white;
            color: $main-text;
            font-size: 20px;
            width: 35px;
            height: 35px;
            padding: 4px 0 0;
            margin: 0 6px;
            text-align: center;
            transition: border-color 0.2s linear,
                        color 0.2s linear;
            &:hover {
                border-color: $earth-yellow;
                color: #000;
            }
            @media (max-width: 768px) {
                width: 30px;
                height: 30px;
                font-size: 18px;
                padding: 4px;
            }
        }
    }
    .copy {
        display: inline-block;
        margin: 0;
        color: $white;
        font-size: 12px;
        position: absolute;
        bottom: 0;
        right: 15px;
        @media (max-width: 768px) {
            position: static;
            right: initial;
            width: 100%;
            text-align: center;
            font-size: 10px;
        }
    }
}